/* global config, angular */

'use strict';

angular.module('managerApp.auth').factory('User', ($resource) => {
    return $resource(config.apiURL + 'users/:id/:controller', {
        id: '@_id'
    }, {
        changePassword: {
            method: 'PUT',
            params: {
                controller: 'password'
            }
        },
        get: {
            method: 'GET',
            params: {
                id: 'me'
            }
        }
    });
});